.box{
    width: 246px;
    display: flex;
    justify-content: space-between;
}
.yinjie{
    width: 7px;
    height: 32px;
    border-radius: 5px;
    transition: 0.3s;
    transform: scale(1, 0.1);
}
/* @keyframes tiao {
    0%{
        transform: scale(1,1);
    }
    100%{
        transform: scale(1,0.3);
    }
} */
.one{
    animation: tiao 0.5s 2s linear infinite alternate;
    background: #6adbea;
}
.two{
    animation: tiao 0.5s 1.8s linear infinite alternate;
    background: #76dee2;
}
.three{
    animation: tiao 0.5s 1.6s linear infinite alternate;
    background: #83e1d8;
}
.four{
    animation: tiao 0.5s 1.4s linear infinite alternate;
    background: #8ee4d1;
}
.five{
    animation: tiao 0.5s 1.2s linear infinite alternate;
    background: #99e7c7;
}
.six{
    animation: tiao 0.5s 1s linear infinite alternate;
    background: #a5eabf;
}
.seven{
    animation: tiao 0.4s 1s  linear infinite alternate;
    background: #b1edb6;
}