@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --ratio: 0;
  --vw: 0;
  --vh: 0;
  --head-height: calc(76px * var(--ratio));
  user-select: none;
}


.articleTTTShighlight {
  background: #DEFFDE;
}

.articleVocaBularyHighlight_EEFFEE {
  background: #EEFFEE;
}

.articleVocaBularyHighlight_FFDADF {
  background: #FFDADF;
}

.articleVocaBularyHighlight_FAFFEE {
  background: #FAFFEE;
}

.articleVocaBularyHighlight_EEFBFF {
  background: #EEFBFF;
}

.articleVocaBularyHighlight_F2EEFF {
  background: #F2EEFF;
}



.articleQuizHighlight_DEFFDE {
  background: #DEFFDE;
}

.articleQuizHighlight_FCC7C7 {
  background: #FCC7C7;
}

.articleQuizHighlight_FCFFDE {
  background: #FCFFDE;
}

.articleQuizHighlight_DEF7FF {
  background: #DEF7FF;
}

.articleQuizHighlight_DEE2FF {
  background: #DEE2FF;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #96C9C8 !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #96C9C8 !important;
  border-color: #96C9C8 !important;
}

.ant-progress-circle-path {
  stroke: #96C9C8 !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon svg {
  fill: #96C9C8 !important;
}

.ant-steps-item-title {
  color: #161A19 !important;
  font-size: calc(14px * var(--ratio)) !important;
  font-weight: 500 !important;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(116deg, #FFDBE1 0%, #D3F5F4 100%);
  backdrop-filter: blur(250px); */
  font-size: 16px;
  line-height: 1;
  /* overflow: hidden; */
  /* filter: blur(0px); */
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Flex800';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNNepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNau1OPFu02G5bac.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto Flex800';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNNepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNau8OPFu02G5bac.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */
@font-face {
  font-family: 'Roboto Flex800';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNNepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNau7OPFu02G5bac.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto Flex800';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNNepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNau3OPFu02G5bac.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto Flex800';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNNepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNau2OPFu02G5bac.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto Flex800';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNNepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf3e0O-gn5rrZCu20YNau4OPFu02G5.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


body,
html {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* button {
  outline: none;
  border: none;
  background: none;
} */


/* button:focus{
  outline: none;
  border: none;
  background: none;
} */

input:focus,
textarea:focus {
  outline: none;
  /* border: none;
  background: none; */
}

input,
textarea {
  outline: none;
  border: none;
  background: none;
}

input::placeholder {
  color: #BFBFBF;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.selectOption:hover {
  background: #F6F47B !important;
}

/* 针对谷歌浏览器中的自动填充样式 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: none;
  /* 将阴影效果设置为白色透明 */
  transition: background-color 5000s ease-in-out 0s;
  /* 通过过渡来延迟应用背景颜色变化 */
  font-size: 20px;
}

/*ï¼šè¿™æ˜¯æ¼”ç¤ºä»£ç ,ä¸æ˜¯æºä»£ç ã€‚æºç ä¸‹è½½å”¯ä¸€åœ°å€: http://www.bootstrapmb.com/item/2709(è¿™ä¸ªå¤‡æ³¨ä»…åœ¨æ¼”ç¤ºé¡µé¢æœ‰ï¼Œæºä»£ç é‡Œæ²¡æœ‰)*/
.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotElastic 1s infinite linear
}

.dot-elastic::before,
.dot-elastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff
}

.dot-elastic::before {
  left: -15px;
  animation: dotElasticBefore 1s infinite linear
}

.dot-elastic::after {
  left: 15px;
  animation: dotElasticAfter 1s infinite linear
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1)
  }

  25% {
    transform: scale(1, 1.5)
  }

  50% {
    transform: scale(1, 0.67)
  }

  75% {
    transform: scale(1, 1)
  }

  100% {
    transform: scale(1, 1)
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1)
  }

  25% {
    transform: scale(1, 1)
  }

  50% {
    transform: scale(1, 1.5)
  }

  75% {
    transform: scale(1, 1)
  }

  100% {
    transform: scale(1, 1)
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1)
  }

  25% {
    transform: scale(1, 1)
  }

  50% {
    transform: scale(1, 0.67)
  }

  75% {
    transform: scale(1, 1.5)
  }

  100% {
    transform: scale(1, 1)
  }
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  animation: dotPulse 1.5s infinite linear
}

@keyframes dotPulse {
  0% {
    box-shadow: 9984px 0 0 -5px #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 2px #9880ff
  }

  25% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 2px #9880ff, 10014px 0 0 0 #9880ff
  }

  50% {
    box-shadow: 9984px 0 0 2px #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 -5px #9880ff
  }

  75% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 -5px #9880ff, 10014px 0 0 0 #9880ff
  }

  100% {
    box-shadow: 9984px 0 0 -5px #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 2px #9880ff
  }
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(152, 128, 255, 0);
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(152, 128, 255, 0);
  color: #9880ff
}

.dot-flashing::before {
  left: -15px;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s
}

.dot-flashing::after {
  left: 15px;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0.6s
}

@keyframes dotFlashing {
  0% {
    background-color: rgba(152, 128, 255, 0)
  }

  100% {
    background-color: #FFF
  }
}

.dot-collision {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff
}

.dot-collision::before,
.dot-collision::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(152, 128, 255, 0);
  color: #9880ff
}

.dot-collision::before {
  left: -10px;
  animation: dotCollisionBefore 2s infinite ease-in
}

.dot-collision::after {
  left: 10px;
  animation: dotCollisionAfter 2s infinite ease-in;
  animation-delay: 1s
}

@keyframes dotCollisionBefore {

  0%,
  50%,
  75%,
  100% {
    transform: translateX(0)
  }

  25% {
    transform: translateX(-15px)
  }
}

@keyframes dotCollisionAfter {

  0%,
  50%,
  75%,
  100% {
    transform: translateX(0)
  }

  25% {
    transform: translateX(15px)
  }
}

.dot-revolution {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff
}

.dot-revolution::before,
.dot-revolution::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff
}

.dot-revolution::before {
  left: 0;
  top: -15px;
  transform-origin: 5px 20px;
  animation: dotRevolution 1.4s linear infinite
}

.dot-revolution::after {
  left: 0;
  top: -30px;
  transform-origin: 5px 35px;
  animation: dotRevolution 1s linear infinite
}

@keyframes dotRevolution {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
    transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

.dot-carousel {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  animation: dotCarousel 1.5s infinite linear
}

@keyframes dotCarousel {
  0% {
    box-shadow: 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff
  }

  50% {
    box-shadow: 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff
  }

  100% {
    box-shadow: 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff
  }
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  animation: dotTyping 1.5s infinite linear
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff
  }

  50% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px -10px 0 0 #9880ff, 10014px 0 0 0 #9880ff
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px -10px 0 0 #9880ff
  }

  100% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff
  }
}

.dot-windmill {
  position: relative;
  top: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  transform-origin: 5px 20px;
  animation: dotWindmill 2s infinite linear
}

.dot-windmill::before,
.dot-windmill::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff
}

.dot-windmill::before {
  left: -12.99px;
  top: 22.5px
}

.dot-windmill::after {
  left: 12.99px;
  top: 22.5px
}

@keyframes dotWindmill {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
    transform: rotateZ(720deg) translate3d(0, 0, 0)
  }
}

.dot-bricks {
  position: relative;
  top: 8px;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff;
  animation: dotBricks 2s infinite ease
}

@keyframes dotBricks {
  0% {
    box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff
  }

  8.333% {
    box-shadow: 10007px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff
  }

  16.667% {
    box-shadow: 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff, 10007px 0 0 0 #9880ff
  }

  25% {
    box-shadow: 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff
  }

  33.333% {
    box-shadow: 10007px 0 0 0 #9880ff, 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff
  }

  41.667% {
    box-shadow: 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff
  }

  50% {
    box-shadow: 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff
  }

  58.333% {
    box-shadow: 9991px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff
  }

  66.666% {
    box-shadow: 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff, 9991px -16px 0 0 #9880ff
  }

  75% {
    box-shadow: 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff
  }

  83.333% {
    box-shadow: 9991px -16px 0 0 #9880ff, 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff
  }

  91.667% {
    box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff
  }

  100% {
    box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff
  }
}

.dot-floating {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFloating 3s infinite cubic-bezier(0.15, 0.6, 0.9, 0.1)
}

.dot-floating::before,
.dot-floating::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff
}

.dot-floating::before {
  left: -12px;
  animation: dotFloatingBefore 3s infinite ease-in-out
}

.dot-floating::after {
  left: -24px;
  animation: dotFloatingAfter 3s infinite cubic-bezier(0.4, 0, 1, 1)
}

@keyframes dotFloating {
  0% {
    left: calc(-50% - 5px)
  }

  75% {
    left: calc(50% + 105px)
  }

  100% {
    left: calc(50% + 105px)
  }
}

@keyframes dotFloatingBefore {
  0% {
    left: -50px
  }

  50% {
    left: -12px
  }

  75% {
    left: -50px
  }

  100% {
    left: -50px
  }
}

@keyframes dotFloatingAfter {
  0% {
    left: -100px
  }

  50% {
    left: -24px
  }

  75% {
    left: -100px
  }

  100% {
    left: -100px
  }
}

.dot-fire {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px -15px 0 0;
  animation: dotFire 1s infinite linear
}

.dot-fire::before,
.dot-fire::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff
}

.dot-fire::before {
  box-shadow: 9999px -5px 0 0;
  animation: dotFireBefore 1s infinite linear
}

.dot-fire::after {
  box-shadow: 9999px 15px 0 0;
  animation: dotFireAfter 1s infinite linear
}

@keyframes dotFire {
  0% {
    box-shadow: 9999px -15px 0 -3px
  }

  1%,
  50% {
    box-shadow: 9999px 15px 0 -5px
  }

  100% {
    box-shadow: 9999px -5px 0 2px
  }
}

@keyframes dotFireBefore {
  0% {
    box-shadow: 9999px -5px 0 2px
  }

  50% {
    box-shadow: 9999px -15px 0 -3px
  }

  51%,
  100% {
    box-shadow: 9999px 15px 0 -5px
  }
}

@keyframes dotFireAfter {
  1% {
    box-shadow: 9999px 15px 0 -5px
  }

  50% {
    box-shadow: 9999px -5px 0 2px
  }

  100%,
  0% {
    box-shadow: 9999px -15px 0 -3px
  }
}

.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear
}

@keyframes dotSpin {

  0%,
  100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)
  }

  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)
  }

  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)
  }

  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)
  }

  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)
  }

  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff
  }

  75% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff
  }

  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #9880ff
  }
}

.dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 0 #9880ff;
  animation: dotFalling 1s infinite linear;
  animation-delay: .1s
}

.dot-falling::before,
.dot-falling::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff
}

.dot-falling::before {
  animation: dotFallingBefore 1s infinite linear;
  animation-delay: 0s
}

.dot-falling::after {
  animation: dotFallingAfter 1s infinite linear;
  animation-delay: .2s
}

@keyframes dotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0)
  }

  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 #9880ff
  }

  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0)
  }
}

@keyframes dotFallingBefore {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0)
  }

  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 #9880ff
  }

  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0)
  }
}

@keyframes dotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0)
  }

  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 #9880ff
  }

  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0)
  }
}

.dot-stretching {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotStretching 2s infinite ease-in;
  transform: scale(1.25, 1.25)
}

.dot-stretching::before,
.dot-stretching::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff
}

.dot-stretching::before {
  animation: dotStretchingBefore 2s infinite ease-in
}

.dot-stretching::after {
  animation: dotStretchingAfter 2s infinite ease-in
}

@keyframes dotStretching {
  0% {
    transform: scale(1.25, 1.25)
  }

  50%,
  60% {
    transform: scale(.8, .8)
  }

  100% {
    transform: scale(1.25, 1.25)
  }
}

@keyframes dotStretchingBefore {
  0% {
    transform: translate(0) scale(.7, .7)
  }

  50%,
  60% {
    transform: translate(-20px) scale(1, 1)
  }

  100% {
    transform: translate(0) scale(.7, .7)
  }
}

@keyframes dotStretchingAfter {
  0% {
    transform: translate(0) scale(.7, .7)
  }

  50%,
  60% {
    transform: translate(20px) scale(1, 1)
  }

  100% {
    transform: translate(0) scale(.7, .7)
  }
}

.dot-shuttle {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #000;
  color: transparent;
  margin: -1px 0;
  filter: blur(2px)
}

.dot-shuttle::before,
.dot-shuttle::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: -50px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #000;
  color: transparent;
  opacity: 0;
  filter: blur(2px);
  animation: dotShuttle 2s infinite ease-in
}

.dot-shuttle::after {
  animation-delay: 1s
}

@keyframes dotShuttle {
  0% {
    opacity: 0;
    transform: translateX(0)
  }

  50% {
    opacity: 1;
    transform: translateX(50px)
  }

  100% {
    opacity: 0;
    transform: translateX(100px)
  }
}

.dot-hourglass {
  position: relative;
  top: -15px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #000;
  color: transparent;
  margin: -1px 0;
  filter: blur(2px);
  transform-origin: 5px 20px;
  animation: dotHourglass 2.4s infinite ease-in-out;
  animation-delay: .6s
}

.dot-hourglass::before,
.dot-hourglass::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #000;
  color: transparent;
  filter: blur(2px)
}

.dot-hourglass::before {
  top: 30px
}

.dot-hourglass::after {
  animation: dotHourglassAfter 2.4s infinite cubic-bezier(0.65, 0.05, 0.36, 1)
}

@keyframes dotHourglass {
  0% {
    transform: rotateZ(0deg)
  }

  25% {
    transform: rotateZ(180deg)
  }

  50% {
    transform: rotateZ(180deg)
  }

  75% {
    transform: rotateZ(360deg)
  }

  100% {
    transform: rotateZ(360deg)
  }
}

@keyframes dotHourglassAfter {
  0% {
    transform: translateY(0)
  }

  25% {
    transform: translateY(30px)
  }

  50% {
    transform: translateY(30px)
  }

  75% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(0)
  }
}

.dot-overtaking {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: transparent;
  color: #000;
  margin: -1px 0;
  box-shadow: 0 -20px 0 0;
  filter: blur(2px);
  animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2)
}

.dot-overtaking::before,
.dot-overtaking::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: transparent;
  color: #000;
  box-shadow: 0 -20px 0 0;
  filter: blur(2px)
}

.dot-overtaking::before {
  animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
  animation-delay: .3s
}

.dot-overtaking::after {
  animation: dotOvertaking 1.5s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
  animation-delay: .6s
}

@keyframes dotOvertaking {
  0% {
    transform: rotateZ(0deg)
  }

  100% {
    transform: rotateZ(360deg)
  }
}

.dot-emoji {
  position: relative;
  height: 10px;
  font-size: 10px
}

.dot-emoji::before {
  content: 'âš½ðŸ€ðŸ';
  display: inline-block;
  position: relative;
  animation: dotEmoji 1s infinite
}

@keyframes dotEmoji {
  0% {
    top: -20px;
    animation-timing-function: ease-in
  }

  34% {
    transform: scale(1, 1)
  }

  35% {
    top: 20px;
    animation-timing-function: ease-out;
    transform: scale(1.5, 0.5)
  }

  45% {
    transform: scale(1, 1)
  }

  90% {
    top: -20px
  }

  100% {
    top: -20px
  }
}

.hideScroll::-webkit-scrollbar {
  display: none;
}


.scaleAnimation {
  animation: shake 0.5s linear;
}

@keyframes shake {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.3) rotate(3deg);
  }

  50% {
    transform: scale(0.7) rotate(-3deg);
  }

  75% {
    transform: scale(1.2) rotate(-3deg);
  }

  100% {
    transform: scale(1) rotate(0deg);
  }
}


.displayAnimate {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
  background: rgb(255, 255, 232);
}

.article_word_card {
  position: fixed;
  color: #000;
  display: none;
  font-size: 14px;
  top: 0;
  left: 0;
  width: 288px;
  min-height: 98px;
  border-radius: 2px;
  border: 2px solid #1F1D0F;
  background: #FFF;
  padding: 16px;
  z-index: 1111111;

}

.article_word_card .close {
  width: 24px;
  height: 24px;
  position: absolute;
  right: -16px;
  top: -16px;
  text-align: center;
  line-height: 21px;
  border-left: 2px solid #1F1D0F;
  border-bottom: 2px solid #1F1D0F;
  font-size: 24px;
}

.article_word_card_box {
  position: relative;
  background-position: bottom;
  background-size: 60px;
  cursor: pointer;
}

.article_word_card_title {
  color: #1F1D0F;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: block;
  line-height: 16px;
}

.article_word_card_subtitle {
  color: #8B8B8B;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
}

.article_word_card_txt {
  color: #1F1D0F;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  display: block;
  word-break: break-all;
}


@keyframes ldio-hyk492dfhb9 {
  0% {
    background: #f8b26a
  }

  12.5% {
    background: #f8b26a
  }

  12.625% {
    background: #e15b64
  }

  100% {
    background: #e15b64
  }
}

.ldio-hyk492dfhb9 div {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #e15b64;
  animation: ldio-hyk492dfhb9 1s linear infinite;
}

.loadingio-spinner-blocks-vm0cns3xbfb {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  position: fixed;
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.ldio-hyk492dfhb9 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-hyk492dfhb9 div {
  box-sizing: content-box;
}